import {
  getShippingOrderMailConfig,
  sendEmailCallBack,
} from '@/api/stock-center/shipment'
import {
  sendMail,
  uploadFileNoLoading as uploadFile,
  batchUploadFile,
} from '@/api/mail/mail.js'
import getMailContentFromConfig, {
  getSignMialContent,
} from '../helper/mail-content-template'
import { getSettingMsg } from '@/views/system-manage/mail-setting/util/utils.js'
import { wrapperAwait } from '@/utils/utils'
import { URL_COMPRESS } from '@/config'

let tenantId = ''
// 获取发送邮件的内容
export async function sendGoodsMail(
  shippingOrderId,
  { tenantId, shipTime },
  instance
) {
  tenantId = tenantId
  return getShippingOrderMailConfig({
    shippingOrderId,
  }).then((res) => {
    if (res.code == '000000' && res.data && res.data.length > 0) {
      const noSend = res.data.find((item) => !item.sendEmail)
      // 处理imgList
      res.data.forEach((item) => {
        if (Array.isArray(item.productImages)) {
          item.productImages.forEach((cItem) => {
            if (cItem.imgList) {
              cItem.imgList = cItem.imgList.split(',')
            } else {
              cItem.imgList = []
            }
          })
        }
      })
      if (noSend) {
        return instance.$message.warning(
          `委托单${noSend.shipOrderNumber}没有发件人`
        )
      }
      return Promise.resolve([
        //非批量
        {
          ...res.data[0],
          shippingOrderId,
          shipTime,
        },
      ])
    }
  })
}

/**
 *
 * @param {*} config 委托单数据
 * @param {*} instance 组件实例
 */
export async function getMailInfo(config, instance) {
  let [bccEmailList, ccEmailList] = [[], []]

  const res = await getSettingMsg(tenantId)
  const { deliverCCEmail, deliverCCType } = res || {}

  if (deliverCCType === 1 && deliverCCEmail) {
    bccEmailList = (deliverCCEmail && deliverCCEmail.split(',')) || []
  } else {
    ccEmailList = (deliverCCEmail && deliverCCEmail.split(',')) || []
  }
  if (deliverCCEmail) {
    if (deliverCCType) {
      bccEmailList = deliverCCEmail.split(',')
    } else {
      ccEmailList = deliverCCEmail.split(',')
    }
  }
  config.bccEmailList = bccEmailList
  config.ccEmailList = ccEmailList

  let header = instance.pubApi.exportHeader.find((obj) => {
    return config.exportsRise == obj.code
  })

  let content = getMailContentFromConfig(config, header)

  let title = `Order PO ${config.poNumber}/${config.piNumber}  has been shipped out`

  // 获取临时文件 预览使用，还未上传到邮件服务器
  let enclosureList = getTempEnclosureList(config)

  let sendForm = {
    singleDisplay: 0, // 1群发单显 0取消
    bccEmailList: config.bccEmailList, // 密送人
    ccEmailList: config.ccEmailList, // 抄送人
    content, // 邮件内容
    emailAccount: config.sendEmail, // 发件人
    enclosureList,
    receiveList: [config.receiveEmail], // 收件人 test@qifanship.com
    title, // 主题
    businessId: config.businessId,
    tenantId: config.tenantId,
    shipOrderNumber: config.shipOrderNumber,
    mailServer: config.mailServer,
  }
  return Promise.resolve(mergeMailInfo(sendForm))
}

// 上传附件到邮件模块
function _uploadFile(file, done) {
  if (file.size / 1024 / 1024 >= 20) {
    this.$message.error(`${v.name}大小超过20M，无法上传`)
    return
  }
  const fileObj = {
    file,
    flagSaveName: 1,
    source: 1,
    uploadType: 1,
  }
  return new Promise((resolve, reject) => {
    uploadFile(fileObj).then(
      (res) => {
        if (res.code == '000000') {
          resolve({
            enclosureName: `${file.name}`,
            fileId: res.data.fileId,
            enclosureSize: res.data.size,
            enclosureUrl: res.data.path,
            suffix: res.data.suffix,
            uploadType: 1,
            mailEnclosureId: res.data.fileId,
            source: 1,
          })
        } else {
          if (typeof done === 'function') {
            done()
          }
          reject(res)
        }
      },
      (err) => {
        if (typeof done === 'function') {
          done()
        }
        reject(err)
      }
    )
  })
}
// 将地址转化为文件对象
function _getFileByUrl(url, enclosureName, fileType) {
  /**
   * 获取 blob
   * @param  {String} url 目标文件地址
   * @return {cb}
   */
  function getBlob(url) {
    url = url.startsWith('https')
      ? url
      : 'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' + url
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject('下载发票失败')
        }
      }
      xhr.send()
    })
  }
  const s = enclosureName.split('.')
  const type = s[s.length - 1]
  // console.log(type, 'type=========')
  console.log(fileType, 'fileType=========')
  return getBlob(url).then((blob) => {
    return new window.File([blob], `${enclosureName}`, {
      type: fileType || type,
    })
  })
}

/**
 * 判断是否是从出运发货邮件点击跳转到写邮件页面
 * @param {*}
 */
export function isShipment() {
  return this.$route.query.from === 'ship'
}

/**
 * 是否是签收邮件
 */
export function isSign() {
  return this.$route.query.from === 'sign'
}

/**
 * 获取发货邮件内容
 * @param {*}
 */
export function getSendGoodMailContent() {
  const sh = this.$route.query.sh
  let mailInfo = localStorage.getItem(sh)

  if (mailInfo) {
    mailInfo = JSON.parse(mailInfo)

    this.sendForm = {
      ...this.sendForm,
      ...mailInfo,
    }
    const contentCacheKey = sh + 'MAIL_CONTENT',
      localMailContent = localStorage.getItem(contentCacheKey),
      showContent = localMailContent || mailInfo.content

    if (mailInfo.content) {
      setTimeout(() => {
        this.$refs.ueditor.setReadyContent(showContent)
      }, 666)
    }
    if (mailInfo.bccEmailList?.length > 0) {
      this.ccShowFlag = true
      this.bccVisible = true
    }
    if (mailInfo.ccEmailList?.length > 0) {
      this.ccVisible = true
    }
  }
}

/**
 * 本地保存发货邮件内容
 */
export function localSaveShipMailContent() {
  if (!this.sendForm.receiveList.length) {
    return this.$message.warning('请选择收件人')
  }
  this.$message.success('保存成功')
  const { shipOrderNumber } = this.sendForm
  this.sendForm.content = this.$refs.ueditor.getUEContent()
  localStorage.setItem(shipOrderNumber, JSON.stringify(this.sendForm))
  // fixbug 邮件内容单独存储为一个字段
  localStorage.setItem(shipOrderNumber + 'MAIL_CONTENT', this.sendForm.content)

  setTimeout(() => {
    window.close()
  }, 500)
}

/**
 * 邮件内容和图片处理
 * @param {*} list
 */
export async function mergeMailInfo(mailInfo) {
  let { shipOrderNumber } = mailInfo // 委托单号
  let localMailInfo = localStorage.getItem(shipOrderNumber) //获取本地缓存
  if (!localMailInfo) {
    return mailInfo
  } else {
    localMailInfo = JSON.parse(localMailInfo)
    const { enclosureList, content } = mailInfo
    return {
      ...mailInfo,
      ...localMailInfo, // content等其他字段取本地缓存的
      enclosureList: enclosureList || [], //附件取最新的
      content,
    }
  }
}

/**
 * 获取临时附件
 * @param {*} config
 * @returns
 */
export function getTempEnclosureList(config) {
  const { productImages, piNumber, poNumber, invoiceUrl } = config,
    enclosureList = []

  invoiceUrl &&
    enclosureList.push({
      enclosureName: `${piNumber}-${poNumber}-invoice.pdf`,
      enclosureUrl: invoiceUrl,
      isInvoice: true,
    })

  productImages.forEach((item) => {
    const { sku } = item
    item.imgList.forEach((cItem, cIndex) => {
      enclosureList.push({
        enclosureName: `${piNumber}_${sku}_${cIndex + 1}.png`,
        enclosureUrl: cItem,
      })
    })
  })

  return enclosureList
}

/**
 * 发送邮件
 */
export async function sendMailRequest(sendForm) {
  // 处理附件
  let { enclosureList } = sendForm
  const files = []
  for (let i = 0; i < enclosureList.length; i++) {
    const { enclosureUrl, enclosureName } = enclosureList[i]
    const file = await _getFileByUrl(enclosureUrl, enclosureName)
    files.push(file)
  }
  let resFiles = []
  for (let i = 0; i < files.length; i++) {
    const fileObj = await _uploadFile(files[i])
    resFiles.push(fileObj)
  }
  sendForm.enclosureList = resFiles

  return sendMail(sendForm).then((res) => {
    if (res && res.code === '000000') {
      sendEmailCallBack(sendForm.shippingOrderId)
    }
    return res
  })
}

/**
 * 更新缓存中的邮件内容
 */

export function updateMailContent(config, newContent) {
  // 获取委托单号
  const { shipOrderNumber } = config
  let mailInfo = localStorage.getItem(shipOrderNumber)
  if (shipOrderNumber) {
    // 获取缓存中的内容

    if (mailInfo) {
      mailInfo = JSON.parse(mailInfo)
      mailInfo.content = newContent
    }
    localStorage.setItem(shipOrderNumber, JSON.stringify(mailInfo))
  }
}

export async function getSignMailContent({ shippingOrderId, ctx: instance }) {
  let config = null
  // 第一步 获取配置
  const [err, res] = await wrapperAwait(
    getShippingOrderMailConfig({ shippingOrderId })
  )
  if (res) {
    if (res.code == '000000' && res.data && res.data.length > 0) {
      const noSend = res.data.find((item) => !item.sendEmail)
      if (noSend) {
        return instance.$message.warning(
          `委托单${noSend.shipOrderNumber}没有发件人`
        )
      }
      config = {
        ...res.data[0],
        shippingOrderId,
      }

      // 第二步获取抄送人等信息
      let [bccEmailList, ccEmailList] = [[], []]
      const { deliverCCEmail, deliverCCType } = await getSettingMsg(tenantId)
      if (deliverCCType === 1 && deliverCCEmail) {
        bccEmailList = (deliverCCEmail && deliverCCEmail.split(',')) || []
      } else {
        ccEmailList = (deliverCCEmail && deliverCCEmail.split(',')) || []
      }
      if (deliverCCEmail) {
        if (deliverCCType) {
          bccEmailList = deliverCCEmail.split(',')
        } else {
          ccEmailList = deliverCCEmail.split(',')
        }
      }
      // 获取抄送人
      config.bccEmailList = bccEmailList
      config.ccEmailList = ccEmailList
      // 获取出口抬头
      config.header = instance.pubApi.exportHeader.find((obj) => {
        return config.exportsRise == obj.code
      })
      // 获取邮件标题
      config.title = `Your Order ${config.poNumber}/${config.piNumber}  Has Been Delivered. Please Review.`
      // 获取邮件内容
      config.content = getSignMialContent(config)
      return config
    }
  } else {
    console.log(err)
    ctx.$message.warning('出现错误了，请联系管理员')
  }
}

export function getSignMailInfo() {
  let sendForm = localStorage.getItem('SIGN_MAIL_CONTENT')
  if (sendForm) {
    sendForm = JSON.parse(sendForm)
    sendForm.emailAccount = sendForm.sendEmail

    this.sendForm = sendForm
    if (sendForm.content) {
      setTimeout(() => {
        this.$refs.ueditor.setReadyContent(sendForm.content)
      }, 666)
    }

    if (sendForm.bccEmailList?.length > 0) {
      this.ccShowFlag = true
      this.bccVisible = true
    }
    if (sendForm.ccEmailList?.length > 0) {
      this.ccVisible = true
    }
    if (!this.sendForm.shipNos || this.sendForm.shipNos.length === 0) {
      this.$message({
        showClose: true,
        message: '当前要发送的运单号，没有获取到运单号，请确认！',
        type: 'warning',
        duration: 0,
      })
    }
  }
}

export async function batchUploadImgsWithSrc(enclosureList, done) {
  // 处理附件
  let files = []
  for (let i = 0; i < enclosureList.length; i++) {
    let { url, name, orderCode } = enclosureList[i]
    url = url?.replace(URL_COMPRESS, '')
    let fileType = url?.split('.')?.pop()
    name = name || `${orderCode}-Renderings-${i + 1}.${fileType}`
    const file = await _getFileByUrl(url, name, fileType)
    files.push(file)
  }

  let uploadedFiles = []
  for (let i = 0; i < files.length; i++) {
    if (files[i] instanceof File) {
      const fileObj = await _uploadFile(files[i]).catch((err) => {
        return Promise.reject('')
      })
      console.log('fileObj=======', fileObj)

      uploadedFiles.push(fileObj)
    }
  }

  return uploadedFiles
}
