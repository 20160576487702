<template>
  <el-dialog title="选择大货照" :visible.sync="option.show" width="1200px" @close="close" :close-on-click-modal="false">
    <!-- 委托单列表 -->
    <div class="list-wrapper">
      <!-- 列表项 -->
      <div class="list-wrapper-item" v-for="(i, index) in list">
        <!-- 表头 -->
        <el-row class="header">
          <el-col :span="4" class="c555 tc fb">委托单号</el-col>
          <el-col :span="4" class="c333 tl fb">{{ i.shipOrderNumber }}</el-col>
          <el-col :span="4" class="c555 tc fb">销售单号</el-col>
          <el-col :span="4" class="c333 tl fb">{{ i.businessNumber }}</el-col>
          <el-col :span="4" class="c333 tl fb">
            <el-checkbox :disabled="!i.invoiceNo" v-model="checkinnvoice"></el-checkbox>
            <span class="ml10">同时发送发票</span>
          </el-col>
          <el-col :span="4" class="tr">
            <span class="page-link pr-10" @click="previewMail(i)">
              邮件内容编辑
            </span>
          </el-col>
        </el-row>
        <div class="product-wrapper" v-for="(item, cIndex) in i.productImages">
          <p>
            <span class="c555">产品编码：</span>
            <span class="c555 mr-10">{{ item.sku }}</span>
            <span class="c999 mr-15">已选{{ item.imgList.length }}张</span>
            <span class="page-link" @click="localUpload(index, cIndex)">
              本地上传
            </span>
            <UploadImage @upload-img-success="addImg" class="upload-image" :ref="'uploadImage' + index"
              :maxSize="1024 * 1024 * 20" :limit="999999999" />
          </p>
          <ErpImageSel :imgList="item.imgList" @del-img="(i) => delImg(i, index, cIndex)"
            @sel-img="(i) => getimgList(i, index, cIndex)" />
        </div>
      </div>
    </div>
    <!-- 发货邮件 -->
    <PhotoList :imgList="imgList" ref="pl" @img-sel="onImgSel" :pIndex="pIndex" :cIndex="cIndex" />
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 认</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import PhotoList from './photo-list'
  import UploadImage from '@/components/uploadImage'
  import { getMailInfo, sendMailRequest } from '../../helper/send-mail'
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
  import { Loading } from 'element-ui'
  import { mapGetters } from 'vuex'
  export default {
    name: 'SelectBigGoodPhoto',
    props: {
      option: {
        type: Object,
        default: () => ({
          show: false, //弹窗控制
        }),
      },

      list: {
        type: Array,
        default: () => [], //委托单列表
      },
      shippingOrderId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        visible: false, // 表格是否可见
        imgList: [], // 所有图片
        pIndex: -1, //委托单索引
        cIndex: -1, //产品索引

        checkinnvoice: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
    },
    components: {
      PhotoList,
      UploadImage,
    },
    methods: {
      // 本地上传
      localUpload(index, cIndex) {
        this.pIndex = index
        this.cIndex = cIndex
        this.$refs['uploadImage' + index][0].$el
          .querySelector('.el-upload__input')
          .click()
      },
      addImg(url) {
        const { pIndex, cIndex } = this
        this.list[pIndex].productImages[cIndex].imgList.push(url)
      },
      // 图片索引imgIndex,rowIndex:行索引
      delImg(imgIndex, rowIndex, cIndex) {
        this.list[rowIndex].productImages[cIndex].imgList.splice(imgIndex, 1)
      },
      //添加远程图片
      onImgSel(checked, delChecked, pIndex, cIndex) {
        let imgList = this.list[pIndex].productImages[cIndex].imgList
        //添加图片
        checked.forEach((c) => {
          if (!imgList.includes(c)) {
            imgList.push(c)
          }
        })
        // imgList = imgList.push(...checked)
        // 删除图片
        imgList = imgList.filter((item) => !delChecked.includes(item))
        // 重新赋值
        this.list[pIndex].productImages[cIndex].imgList = imgList
      },
      //关闭
      close() {
        this.option.show = false
        localStorage.removeItem(this.list[0].shipOrderNumber)
        localStorage.removeItem(this.list[0].shipOrderNumber + 'MAIL_CONTENT')
        this.checkinnvoice = false
      },
      // 图片张数校验
      _checkImgCounts() {
        const maxCount = 20,
          list = this.list
        let checkResult = true
        for (let i = 0; i < list.length; i++) {
          const { productImages, shipOrderNumber } = list[i]
          if (
            productImages.reduce((prev, next) => {
              return (prev += next.imgList.length)
            }, 0) > maxCount
          ) {
            this.$message.warning(
              `委托单${shipOrderNumber}中的所有产品图片限制${maxCount}张图片`
            )
            checkResult = false
            break
          }
        }
        return checkResult
      },
      //保存
      async save() {
        if (!this._checkImgCounts()) {
          return
        }

        const { invoiceUrl, collectionUpdate, piNumber } = this.option.content
        const href = `${window.origin}/#/order/orderList/orderDetail?orderCode=${piNumber}`
        if (!invoiceUrl) {
          return this.$confirm(
            `<p><a href=${href} target="_blank">${piNumber}</a>未生成发票,是否继续发送邮件？

          </p>
            <p style="color:gray;font-size:10px;"> 若继续发送，邮件内容中包含invoice的词语会被隐藏。</p>
          `,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              dangerouslyUseHTMLString: true,
            }
          ).then(() => {
            this._send()
          })
        }

        if (collectionUpdate && this.checkinnvoice) {
          return this.$confirm(
            `<p><a href=${href} target="_blank">${piNumber}</a>发票金额发生变动，请重新生成发票！</p>
            <p style="color:gray;font-size:10px;">若确定发送邮件，带出的发票附件可能不是最新数据，请谨慎操作。
            </p>
          `,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              dangerouslyUseHTMLString: true,
            }
          ).then(() => {
            this._send()
          })
        }
        this._send()
      },

      async _send() {
        const config = {
          ...this.list[0],
          checkinnvoice: this.checkinnvoice,
        }
        const sendForm = await getMailInfo(config, this)

        let localContent =
          localStorage.getItem(config.shipOrderNumber + 'MAIL_CONTENT') ||
          sendForm.content
        sendForm.content = localContent
        let loadingInstance = Loading.service({
          fullscreen: true,
          background: 'rgba(0, 0, 0, 0.4)',
          text: '发送中...',
        })
        if (!this.checkinnvoice) {
          sendForm.enclosureList = sendForm.enclosureList.filter(
            (item) => !item.isInvoice
          )
        }
        sendMailRequest({
          ...sendForm,
          shippingOrderId: this.shippingOrderId,
          tenantId: this.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            setTimeout(() => {
              loadingInstance.close()
              this.option.show = false
              this.$emit('refresh')
            }, 500)
          } else {
            loadingInstance.close()
          }
        })
        // setTimeout(() => {
        //   loadingInstance.close()
        //   this.option.show = false
        //   this.$emit('refresh')
        // }, 1000 * 2)
      },

      // 邮件预览
      async previewMail(config) {
        this.$set(config, 'checkinnvoice', this.checkinnvoice)
        const { shipOrderNumber } = config // 委托单号
        const mailInfo = await getMailInfo(config, this)
        if (!this.checkinnvoice) {
          // 去除发票附件，不要直接将enclosureList设置为[]
          mailInfo.enclosureList = mailInfo.enclosureList.filter(
            (item) => !item.isInvoice
          )
        }
        mailInfo.shipOrderNumber = shipOrderNumber
        localStorage.setItem(shipOrderNumber, JSON.stringify(mailInfo))
        console.log(mailInfo)
        let routeData = this.$router.resolve({
          path: '/write',
          query: { ns: 1, from: 'ship', sh: shipOrderNumber },
        })
        // window.open(routeData.href, '_blank')
        this.utils.openWindow(routeData.href)
      },

      // 获取待选择的图片
      async getimgList(i, index, cIndex) {
        let self = this
        this.pIndex = index
        this.cIndex = cIndex
        // 添加orderProductUniqueId
        const { sku, parentSku, orderProductUniqueId } =
          self.list[index].productImages[cIndex],
          { piNumber: businessNumber } = this.option.content
        const res = await ShippingPlanInteractor.listAllInspectionProductImage([
          {
            sku,
            orderProductUniqueId,
            parentSku,
            businessNumber,
          },
        ])
        let imgList = []
        if (res && res.code === '000000') {
          if (res.data?.length > 0) {
            imgList = res.data[0]?.imgList ? res.data[0]?.imgList.split(',') : []
          }
        }
        // 添加orderProductUniqueId
        const d_imgList = self.list[index].productImages.find(
          (item) =>
            item.sku === sku &&
            item.parentSku === parentSku &&
            item.orderProductUniqueId === orderProductUniqueId
        )?.imgList

        self.imgList = imgList.map((item) => {
          const obj = {
            src: item,
          }
          obj.checked = d_imgList.includes(item)
          obj.outChoose = obj.checked // 外部选择了
          return obj
        })
        this.$refs.pl.showDialog()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .list-wrapper {
    border: 1px solid #eee;

    .header {
      background-color: #f6f8f9;

      .el-col {
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding: 5px;

        &:last-child {
          border-right: none;
        }
      }
    }

    .product-wrapper {
      padding: 10px 10px 15px 8px;

      p {
        padding-left: 5px;
      }

      /* .img-list {
        .img-wrapper {
          height: 100px;
          width: 100px;
          display: inline-block;
          margin: 5px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .mask {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.8);
          }
          &:hover {
            div {
              display: block;
            }
          }
        }
      } */
    }

    .upload-image {
      position: absolute;
      left: -20000px;
    }

    .c555 {
      color: #555;
    }

    .c333 {
      color: #333;
    }

    .c999 {
      color: #999;
    }

    .tl {
      text-align: left;
    }

    .tc {
      text-align: center;
    }

    .tr {
      text-align: right;
    }

    .fb {
      font-weight: 600;
    }

    .mr-10 {
      margin-right: 10px;
    }

    .pr-10 {
      padding-right: 10px;
    }

    .mr-15 {
      margin-right: 15px;
    }
  }
</style>
