<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月10日 13:12:35
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-row>
    <h4>发货信息</h4>
    <div class="custom-detail">
      <template v-for="(item, index) in shipNos">
        <el-row :key="index + '1'">
          <el-col>
            <em>运输方式：</em>
            <span>
              {{ item.shippingMethod }}
            </span>
          </el-col>
          <el-col>
            <em>运单号:</em>
            <span>
              {{ item.shipNo }}
            </span>
          </el-col>
          <el-col>
            <em style="width: 110px">预计到达时间：</em>
            <span style="width: calc(100% - 110px)">
              {{ item.arriveTime ? item.arriveTime.split(' ')[0] : '--' }}
            </span>
          </el-col>

          <el-col>
            <em>总重量:</em>
            <span>{{ item.grossWeight || '--' }}</span>
          </el-col>
        </el-row>

        <el-row :key="index + '2'">
          <el-col>
            <em>总箱数:</em>
            <span>{{ item.caseNum || '--' }}</span>
          </el-col>
          <el-col>
            <em>总体积：</em>
            <span>{{ item.volume || '--' }}</span>
          </el-col>

          <el-col>
            <em>装箱尺寸：</em>
            <span>{{ item.packingSize || '--' }}</span>
          </el-col>
        </el-row>
        <el-row :key="index + '3'">
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">发货备注：</em>
            <span>{{ item.remarks || '--' }}</span>
          </el-col>
        </el-row>
      </template>
      <el-row>
        <el-col>
          <!-- <em>附件：</em>  -->
          <h4 class="upload-file-box">
            附件
            <span v-if="uploadFileList.length">
              ({{ uploadFileList.length }})
            </span>
            <el-button
              type="text"
              @click="handleDownload"
              class="ml-20"
              v-if="uploadFileList.length"
            >
              <!-- 全部下载 -->
              {{ $t('other.Downloadall') }}
            </el-button>
          </h4>
        </el-col>
      </el-row>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
      ></ErpUpload>
    </div>
  </el-row>
</template>

<script>
  export default {
    name: 'ShippingInformation',
    props: {
      shipNos: {
        type: Array,
        default: () => [],
      },
      businessNumber: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        uploadParams: {
          uploadButton: false,
        },
        uploadFileList: [],
      }
    },

    methods: {
      initFile(shipAccessoryUrl, close) {
        this.uploadFileList = JSON.parse(shipAccessoryUrl)
        this.$refs.ErpUpload.initUpload(shipAccessoryUrl, close)
      },
      // 下载文件
      handleDownload() {
        const fileArr = this.uploadFileList.filter((file) => file.url)
        fileArr.forEach((item, index) => {
          if (item.url.includes('?uploadId')) {
            item.url = item.url.split('?uploadId=')[0]
          }
          const lastDotIndex = item.url.lastIndexOf('.')
          const type = item.url.substring(lastDotIndex + 1)
          if (item.url.includes('http') || item.url.includes('https')) {
            this.download(
              item.url,
              `${this.businessNumber}-${index + 1}.${type}`
            )
          } else {
            this.download(
              'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' + item.url,
              `${this.businessNumber}-${index + 1}.${type}`
            )
          }
        })
      },
      download(url, filename) {
        /**
         * 获取 blob
         * @param  {String} url 目标文件地址
         * @return {cb}
         */

        function getBlob(url, cb) {
          var xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (xhr.status === 200) {
              cb(xhr.response)
            }
          }
          xhr.send()
        }

        /**
         * 保存
         * @param  {Blob} blob
         * @param  {String} filename 想要保存的文件名称
         */

        function saveAs(blob, filename) {
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename)
          } else {
            var link = document.createElement('a')
            var body = document.querySelector('body')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            // fix Firefox
            link.style.display = 'none'
            body.appendChild(link)
            link.click()
            body.removeChild(link)
            window.URL.revokeObjectURL(link.href)
          }
        }

        getBlob(url, function (blob) {
          saveAs(blob, filename)
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .upload-file-box {
    display: flex;
    align-items: center;
    span {
      width: auto !important;
    }
    .el-button {
      text-align: left !important;
    }
  }
</style>
